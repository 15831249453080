import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { ContactUs } from '../handelrs/contact.handler';
function EmailContact() {
  const phoneRegExp = RegExp(
    /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
  );
  const [userInfo, setUserInfo] = useState({
    name: '',
    email: '',
    mobile: '',
    caseSubject: '',
    caseDescription: '',
  });
  useEffect(() => {});
  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setUserInfo({ ...userInfo, [name]: value });
  };
  const getData = async () => {
    if (
      userInfo.name !== '' &&
      userInfo.email !== '' &&
      // userInfo.mobile &&
      // phoneRegExp.test(userInfo.mobile) &&
      userInfo.caseDescription !== '' &&
      userInfo.caseSubject !== ''
    ) {
      const data = {
        name: userInfo.name,
        email: userInfo.email,
        mobile: userInfo.mobile,
        caseSubject: userInfo.caseSubject,
        caseDescription: userInfo.caseDescription,
      };
      // setLoading(true);

      ContactUs(data).then((resp) => {
        if (resp.data.status === 200) {
          toast.info(
            `Thank you for reaching out to us, kindly allow us some time to get back to you ,one of our representative would be happy to assist you soonest.`,
            {
              position: 'top-center',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          setUserInfo({
            name: '',
            email: '',
            mobile: '',
            caseSubject: '',
            caseDescription: '',
          });
        } else {
          toast.info(`${resp.data.message}`, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
    } else if (userInfo.name === '') {
      toast.error('Please Enter Name', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (userInfo.email === '') {
      toast.error('Please Enter Email', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    // else if (userInfo.mobile === '') {
    //   toast.error('Please Enter Mobile', {
    //     position: 'top-center',
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // }
    else if (userInfo.caseDescription === '') {
      toast.error('Please Enter Description', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (userInfo.caseSubject === '') {
      toast.error('Please Enter case subject', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    // else if (!phoneRegExp.test(userInfo.mobile)) {
    //   toast.error('Please Enter valid mobile number', {
    //     position: 'top-center',
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //   });
    // }
  };
  return (
    <div>
      <section className="contact-section contact-section-s2 section-padding">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <div className="section-title-s5">
                {/* <span>Connect with Us</span> */}
                <h2>We’d Love to Hear From You!</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col col-lg-8 col-lg-offset-2">
              <div className="content-area">
                {/* <p>
                  Aenean imperdiet. Etiam ultricies nisi vel augue. Curabitur
                  ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus.
                  Maecenas tempus, tellus eget condimentum rhoncus, sem quam
                </p> */}
                <div className="contact-form">
                  <form
                    method="post"
                    className="contact-validation-active"
                    id="contact-form-main"
                  >
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        id="name"
                        placeholder="Name*"
                        value={userInfo.name}
                        onChange={handleInput}
                      />
                    </div>
                    <div>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Email*"
                        value={userInfo.email}
                        onChange={handleInput}
                      />
                    </div>
                    <div>
                      <input
                        type="text"
                        className="form-control"
                        name="mobile"
                        id="phone"
                        min={0}
                        placeholder="Contact"
                        value={userInfo.mobile}
                        onChange={handleInput}
                      />
                    </div>
                    <div>
                      <select
                        name="caseSubject"
                        className="form-control"
                        value={userInfo.caseSubject}
                        onChange={handleInput}
                      >
                        <option disabled="disabled" value="">
                          Please Select Service
                        </option>
                        <option value="subject1">
                          Software Licensing Advisory
                        </option>
                        <option value="subject2">
                          CyberSecurity Solutions
                        </option>
                        <option value="subject3">
                          {' '}
                          Managed SAM (Software Asset Management)
                        </option>
                        <option value="subject4">
                          Technology Evangelization
                        </option>
                        <option value="subject5">
                          Procurement Optimization
                        </option>
                        {/* <option value="subject6">Security Solutions</option>
                        <option value="subject7">Managed ITAM Services</option> */}
                      </select>
                    </div>
                    <div className="fullwidth">
                      <textarea
                        className="form-control"
                        name="caseDescription"
                        id="note"
                        placeholder="Please share with us some context of your requirement..."
                        value={userInfo.caseDescription}
                        onChange={handleInput}
                      ></textarea>
                    </div>
                    <div className="submit-area">
                      <button
                        type="button"
                        className="theme-btn submit-btn"
                        onClick={getData}
                      >
                        Submit Now
                      </button>
                      <div id="loader">
                        <i className="ti-reload"></i>
                      </div>
                    </div>
                    <div className="clearfix error-handling-messages">
                      <div id="success">Thank you</div>
                      <div id="error">
                        {' '}
                        Error occurred while sending email. Please try again
                        later.{' '}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </section>
    </div>
  );
}

export default EmailContact;
