import React, { useEffect, useState } from "react";
import Navbar from "../Component/Navbar";
import Footer from "../Component/Footer";
import { GetAllGallery } from "../handelrs/gallery.handler";
import LoadingSpinner from "../Component/partials/LoadingSpinner";

const dummyData = [
  {
    src: "https://t3.ftcdn.net/jpg/06/48/79/10/360_F_648791013_cQK30SdyiLrVQ96Bqn2MOkz4JmvgttGr.jpg",
    type: "image",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati eaque rerum! Provident similique tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat.",
  },
  {
    src: "https://buffer.com/library/content/images/size/w1200/2023/10/free-images.jpg",
    type: "image",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat.",
  },
  {
    src: "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
    type: "image",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat.",
  },
  {
    src: "https://t3.ftcdn.net/jpg/06/48/79/10/360_F_648791013_cQK30SdyiLrVQ96Bqn2MOkz4JmvgttGr.jpg",
    type: "image",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat.",
  },
  {
    src: "https://buffer.com/library/content/images/size/w1200/2023/10/free-images.jpg",
    type: "image",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat.",
  },
  {
    src: "https://dfstudio-d420.kxcdn.com/wordpress/wp-content/uploads/2019/06/digital_camera_photo-1080x675.jpg",
    type: "image",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime mollitia, molestiae quas vel sint commodi repudiandae consequuntur voluptatum laborum numquam blanditiis harum quisquam eius sed odit fugiat iusto fuga praesentium optio, eaque rerum! Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! Impedit sit sunt quaerat.",
  },
];

const Gallery = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    setLoading(true);
    GetAllGallery(data)
      .then((res) => {
        if (res.data.status === 200) {
          console.log("res", res);
          setData(res.data.data.galleryList);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
        setLoading(false);
      });
  }, []);

  return (
    <>
      <header id="header" className="site-header header-style-1">
        <Navbar />
      </header>

      <section className="page-title-partner-with-us gallery-header-container">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <h2 style={{ textTransform: "uppercase" }}>Partnerships</h2>
            </div>
          </div>
        </div>
      </section>

      <section className="section-gallery">
        <div className="container">
          <div className="gallery-grid-container">
            {!loading &&
              data &&
              data.length > 0 &&
              data.map((item) => {
                const isTypeImage =
                  item.type === "image" ||
                  item.image.includes(".jpg") ||
                  item.image.includes(".png") ||
                  item.image.includes(".jpeg");

                return (
                  <div className="gallery-grid-item">
                    <div className="gallery-grid-item-imageContainer">
                      {isTypeImage ? (
                        <img src={item.image} alt={item.title} />
                      ) : (
                        <video src={item.image} controls />
                      )}
                    </div>
                    <h3 className="gallery-grid-item-heading">{item.title}</h3>
                    <p>
                      {item.description.length > 400
                        ? `${item.description.slice(0, 400)}...`
                        : item.description}
                    </p>
                  </div>
                );
              })}
          </div>

          {loading && (
            <div className="gallery-loadingContainer">
              <LoadingSpinner />
            </div>
          )}
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Gallery;
