import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../Component/Footer";
import Navbar from "../Component/Navbar";
import axios from "axios";
import { Link } from "react-router-dom";
import SingleProject from "../Component/SingleProject";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { GetAllCaseStudies } from "../handelrs/caseStudies.handler";
import Slider from "react-slick";

import sliderImg1 from "../assets/images/slider/team-page-1.jpg";
import sliderImg2 from "../assets/images/slider/team-page-2.jpg";

function Team() {
  const navigate = useNavigate();
  const [allcaseStudies, setAllcaseStudies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = React.useState(1);
  const [count, setCount] = useState(0);
  const [openSingle, setOpenSingle] = useState({
    title: "",
    description: "",
  });
  // useEffect(() => {
  //   getData();
  //   // const script = document.createElement("script");

  //   // script.src = "http://52.250.20.141/silverleaf-backend/script/script.js";
  //   // script.async = true;

  //   // document.body.appendChild(script);

  //   // return () => {
  //   //   document.body.removeChild(script);
  //   // };\
  //   // jQuerycode();
  //   // console.log("check", $(".projects-slider").val());
  // }, [page]);
  const handlePageClick = (event, newPage) => {
    setPage(newPage);
  };
  const data = {
    page: page,
    limit: 6,
  };
  const getData = async () => {
    setLoading(true);
    GetAllCaseStudies(data).then((resp) => {
      if (resp.data.status === 200) {
        setAllcaseStudies(resp.data.data.caseStudyList);
        setCount(resp.data.data.totalPages);
        setLoading(false);
      }
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <div>
      <div className="page-wrapper">
        {/* <!-- start prel?oader --> */}
        {loading && (
          <div className="preloader">
            <div className="sk-cube-grid">
              <div className="sk-cube sk-cube1"></div>
              <div className="sk-cube sk-cube2"></div>
              <div className="sk-cube sk-cube3"></div>
              <div className="sk-cube sk-cube4"></div>
              <div className="sk-cube sk-cube5"></div>
              <div className="sk-cube sk-cube6"></div>
              <div className="sk-cube sk-cube7"></div>
              <div className="sk-cube sk-cube8"></div>
              <div className="sk-cube sk-cube9"></div>
            </div>
          </div>
        )}
        {/* <!-- end preloa?der --> */}

        {/* <!-- Start header --> */}
        <header id="header" className="site-header header-style-1">
          <Navbar />
        </header>
        {/* <!-- end of header --> */}

        {/* <section className="page-title-team">
          <div className="container">
            <div className="row">
              <div className="col col-xs-12 page-title-team-mobile">
                <h2>TEAM</h2>
              </div>
            </div>
          </div>
        </section> */}

        <section className="about-page-slider-section">
          <h2 className="about-page-slider-headingContainer">TEAM</h2>
          <div className="about-page-slider-overlay" />
          <Slider {...settings}>
            <div className="about-page-slider-imageContainer">
              <img src={sliderImg1} alt="slider-img-1" />
            </div>
            <div className="about-page-slider-imageContainer">
              <img src={sliderImg2} alt="slider-img-2" />
            </div>
          </Slider>
        </section>

        {/* <section className="case-study-section section-padding">
          <div className="container">
            <div className="row">
              <div className="col col-lg-5 col-md-5">
                <div className="section-title-s3">
                  <span>CASE STUDIES</span>
                  <h2>Check our latest case studies,doen for client!</h2>
                </div>
              </div>
              <div className="col col-lg-6 col-lg-offset-1 col-md-7">
                <div className="text">
                
                </div>
              </div>
            </div>
          </div> */}

        {/* </section> */}
        <section className="team-section section-padding">
          <div className="container">
            <div className="row">
              <div className="col col-lg-4 col-lg-offset-4 col-sm-6 col-sm-offset-3">
                <div className="section-title-s5">
                  {/* <span>Members</span> */}
                  <h2>Global Leadership Team</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col col-md-12">
                <div className="team-grids">
                  <div className="grid">
                    <div className="img-social">
                      <div className="img-holder">
                        <img
                          src="assets/images/team/Mayank_Srivastava.jpg"
                          alt="teams"
                        />
                      </div>
                      <div className="social">
                        <ul>
                          <li>
                            <a
                              href="https://www.linkedin.com/in/mayank-srivastava-6a20022/"
                              target="_blank"
                            >
                              <i className="ti-linkedin"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="details">
                      <h3>Mayank Srivastava </h3>
                      <span>Founder & CEO</span>
                    </div>
                  </div>

                  <div className="grid">
                    <div className="img-social">
                      <div className="img-holder">
                        <img
                          src="assets/images/team/Sushrut_Parti.jpg"
                          alt="teams"
                        />
                      </div>
                      <div className="social">
                        <ul>
                          {/* <li>
                            <a href="#">
                              <i className="ti-twitter-alt"></i>
                            </a>
                          </li> */}
                          <li>
                            <a
                              href="https://www.linkedin.com/in/sushrut-parti-47a1aa19/"
                              target="blank"
                            >
                              <i className="ti-linkedin"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="details">
                      <h3>Sushrut Parti</h3>
                      <span>
                        Vice-President, Business Development & Strategy
                      </span>
                    </div>
                  </div>

                  <div className="grid">
                    <div className="img-social">
                      <div className="img-holder">
                        <img
                          src="assets/images/team/Sunny_Thusoo.png"
                          alt="teams"
                        />
                      </div>
                      <div className="social">
                        <ul>
                          <li>
                            <a
                              href="https://www.linkedin.com/in/sunny-thusoo-21098b7/"
                              target="_blank"
                            >
                              <i className="ti-linkedin"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="details">
                      <h3>Sunny Thusoo</h3>
                      <span>Director – SAM Services</span>
                    </div>
                  </div>

                  <div className="grid">
                    <div className="img-social">
                      <div className="img-holder">
                        <img
                          src="assets/images/team/narayan-roy.jpg"
                          alt="teams"
                        />
                      </div>
                      <div className="social">
                        <ul>
                          <li>
                            <a
                              href="https://www.linkedin.com/in/narayan-roy-55843747/"
                              target="_blank"
                            >
                              <i className="ti-linkedin"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="details">
                      <h3>Narayan Roy</h3>
                      <span>National Head- Govt. Business (Canada & USA)</span>
                    </div>
                  </div>

                  <div className="grid">
                    <div className="img-social">
                      <div className="img-holder">
                        <img
                          src="assets/images/team/leena_HS.png"
                          alt="teams"
                        />
                      </div>
                      <div className="social">
                        <ul>
                          {/* <li>
                            <a href="#">
                              <i className="ti-twitter-alt"></i>
                            </a>
                          </li> */}
                          {/* <li>
                            <a href="#" target="_blank">
                              <i className="ti-linkedin"></i>
                            </a>
                          </li> */}
                        </ul>
                      </div>
                    </div>
                    <div className="details">
                      <h3> Leena Keshwani</h3>
                      <span>Director, Sales (USA)</span>
                    </div>
                  </div>

                  <div className="grid">
                    <div className="img-social">
                      <div className="img-holder">
                        <img
                          src="assets/images/team/Ankit_Raj_Awasth.png"
                          alt="teams"
                        />
                      </div>
                      <div className="social">
                        <ul>
                          <li>
                            <a
                              href="https://www.linkedin.com/in/ankitrajawasthi"
                              target="_blank"
                            >
                              <i className="ti-linkedin"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="details">
                      <h3>Ankit Raj Awasthi</h3>
                      <span>Director, Sales (Canada)</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- end container --> */}
        </section>

        {/* <!-- end case-study-section --> */}

        {/* <!-- start news-letter-section --> */}
        {/* <section className="news-letter-section">
          <div className="container">
            <div className="row">
              <div className="col col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2">
                <div className="newsletter">
                  <h3>Email Newsletter</h3>
                  <p>
                    Aenean leo ligula porttitor eu consequat vitae eleifend ac
                    enim. Aliquam lorem ante dapibus in viverra quiss consequat
                    vitae
                  </p>
                  <div className="newsletter-form">
                    <form>
                      <div>
                        <input type="text" className="form-control" />
                        <button type="submit">Subscrib</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <!-- end news-letter-section --> */}

        {/* <!-- start site-footer --> */}
        <Footer />

        {/* <!-- end site-footer --> */}
        {/* <SingleProject openSingle={openSingle} setOpenSingle={setOpenSingle} /> */}
      </div>
    </div>
  );
}

export default Team;
