// export const BASE_PATH = `https://api.silverleaf.nssplindia.com/api/`;
// export const ScriptFile = `https://api.silverleaf.nssplindia.com/script/script.js`;
export const BASE_PATH = `https://api.silverleafsolutions.com/api/`;
export const ScriptFile = `https://api.silverleafsolutions.com/script/script.js`;
// export const BASE_PATH = `http://52.250.20.141/silverleaf-backend/api/`;
// export const ScriptFile = `http://52.250.20.141/silverleaf-backend/script/script.js`;
// "proxy": "http://52.250.20.141", package.json line 5

export const API_ROUTES = {
  careers: {
    GET_All_Jobs: `${BASE_PATH}career/getJobs`,
    EDIT_JOB: `${BASE_PATH}career/editJob/id`,
    Apply_JOB: `${BASE_PATH}career/applynow`,
    GET_JOB_LIST: `${BASE_PATH}career/getJobList`,
  },
  CASE_STUDY: {
    GET_ALL_CASE_STUDY: `${BASE_PATH}caseStudy/getCaseStudy`,
    EDIT_CASE_STUDY: `${BASE_PATH}caseStudy/getCaseStudyById`,
  },

  Partner_With_Us: {
    ADD_Partner: `${BASE_PATH}partnerWithUs/addPartner`,
  },

  BANNERS: {
    GET_BANNER: `${BASE_PATH}banner/getBanners`,
  },
  AWARDS: {
    GET_ALL_AWARDS: `${BASE_PATH}awards/getAwards`,
  },
  BLOGS: {
    GET_BLOGS: `${BASE_PATH}blogs/getAllBlogs`,
    GET_BLOGS_BY_ID: `${BASE_PATH}blogs/getBlogById`,
  },
  CONTACT: {
    EMAIL_US: `${BASE_PATH}contact/contactUs`,
  },
  Gallery: {
    GET_All_Gallery: `${BASE_PATH}gallery/getAllGalleryItems`,
  },
};
